import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { detailData } from "../../Reducers/detailsSlice";
import { Loader } from "../loader/Loader";
import HomeImg from "../../assets/Images/Rectangle310.png";
import newsImg from "../../assets/Images/image126.jpg";
import "../../components/news/news.css";
import { setPgcoloreMore, setPillBgColor, setShow } from "../../Reducers/pillBgColorSlice";
import { handleTabClick } from "../filterFunction";
import { setFillerArray } from "../../Reducers/fillerArraySlice";
import { fetchBusinessSubDataMobile, fetchGmpSubDataMobile, fetchNewsSubDataMobile, fetchProductSubDataMobile } from "../../Reducers/allSubcategorySlice";
import { businessData } from "../../Reducers/businessSlice";
import { gmpTrainingData } from "../../Reducers/gmpTrainingSlice";
import { productData } from "../../Reducers/productSlice";
import { setAllData } from "../../Reducers/allDataSlice";
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { categoryData } from "../../Reducers/allCategorySlice";
import { Search } from "@mui/icons-material";


function SearchPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const pillBgColor = useSelector((state: RootState) => state.pillBgColor);
  const show = useSelector((state: RootState) => state.pillBgColor.show);
  const { fillerArray } = useSelector((state: RootState) => state.fillerArray);
  const { ProductSubdata } = useSelector((state: RootState) => state.productCard);
  const { ProductData, status, error } = useSelector((state: RootState) => state.product);
  const { CategoryData } = useSelector((state: RootState) => state.allCategory);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const pgcoloreMore = useSelector((state: RootState) => state.pillBgColor.pgcoloreMore);
  const [showDropdown, setShowDropdown] = useState(false);

  const onTabClick = (tab: string, subCategoryName?: string) => {
    handleTabClick({
      tab,
      subCategoryName,
      data: ProductData,
      dispatch,
    });
    dispatch(setShow(!!subCategoryName));
  };

  const handleDoubleClick = () => {
    setPillBgColor({});
  };

  const handleCardClick = (id: string,prevPage: string) => {
    const selectedNews = ProductData;
    dispatch(detailData({ id })); // Dispatch detailData with the id
    navigate(`/details/${id}`, { state: { selectedNews, prevPage } });
  };

  const handleCardClick2 = (url: string) => {
    window.open(url, '_blank');
  };

  const handleMoreClick = () => {
    setShowDropdown(!showDropdown);
    dispatch(setPgcoloreMore(true));
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const updateCategory= await dispatch(categoryData()).unwrap();
        dispatch(setMobileHeadingData(updateCategory?.Category_E))
        await dispatch(fetchProductSubDataMobile()).unwrap();
        await dispatch(productData()).unwrap();

        const updatedNewsData = await dispatch(productData()).unwrap();
        dispatch(setAllData(updatedNewsData));


        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      {loading && <Loader />}
      <div className="container backgroundblur">
        <div className="mainHeadAbout">
          {/* <span>{CategoryData?.Category_E}</span> */}
          <span> Search</span>
        </div>
        <div style={{ position: "relative" }}>
          {/* <div className="sub-category mt-4">
            <div className="row nav nav-tabs tabs-news">
              <div
                 className={` ${ProductSubdata.length > 5? "col-lg-1 col-md-1 col-sm-2" : "col-lg-2 col-md-2 col-sm-2"} ${pillBgColor["hello"] && pgcoloreMore==false? "bg-color" : ""}`}
                onClick={() => {
                  onTabClick("hello");
                  setShow(false);
                  setShowDropdown(false);
                  dispatch(setPgcoloreMore(false));
                }}
              >
                <div className="navlink">
                  <Link className={`alltext ${pillBgColor["hello"] && pgcoloreMore==false ? "alltext2" : ""}`} to="">
                    All
                  </Link>
                </div>
              </div>

              {ProductSubdata.slice(0, 5).map((tab) =>
                tab.status === "active" ? (
                  <div
                    key={tab._id}
                    className={`col-lg-2 col-md-2 col-sm-2 ${pillBgColor[tab._id] && pgcoloreMore==false? "bg-color" : ""}`}
                    onClick={() => {
                      onTabClick(tab._id, tab.sub_category_name);
                      setShow(true);
                      setShowDropdown(false);
                      dispatch(setPgcoloreMore(false));
                    }}
                  >
                    <div className="navlink">
                      <Link className={`alltext ${pillBgColor[tab._id] && pgcoloreMore==false? "alltext2" : ""}`} to={tab.link}>
                        {tab.sub_category_name}
                      </Link>
                    </div>
                  </div>
                ) : null
              )}

              {ProductSubdata.length > 5 && (
                <div
                  className={`col-lg-1 col-md-1 col-sm-2 ${pillBgColor.more ||  pgcoloreMore ? "bg-color" : ""}`}
                  onClick={handleMoreClick}
                >
                  <div className="navlink">
                    <Link className={`alltext  ${pillBgColor.more ||  pgcoloreMore? "alltext2" : ""}`} to="#">
                      More&nbsp;{showDropdown ?<AiOutlineUp style={{fontSize:"15px"}}/>:<AiOutlineDown style={{fontSize:"15px"}}/>}

                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          {showDropdown && (
            <div className="dropdown-content mt-2 dropdown-news card">
              {ProductSubdata.slice(5).map((tab) =>
                tab.status === "active" ? (
                  <div
                    key={tab._id}
                    className={` ${pillBgColor[tab._id] ? "bg-color" : ""}`}
                    onClick={() => {
                      onTabClick(tab._id, tab.sub_category_name);
                      setShow(true);
                      setShowDropdown(false);
                       dispatch(setPgcoloreMore(true));
                    }}
                  >
                    <div className="navlink">
                      <Link className={`alltext alltext-drop ${pillBgColor[tab._id] ? "alltext2" : ""}`} to={tab.link}>
                        {tab.sub_category_name}
                      </Link>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>


        {(!show ? ProductData.slice(0, 1) : fillerArray.slice(0, 1)).map((data: any) => (
          <div className="row for-hide-web" key={data._id}>
            <div className="col-lg-6 col-md-6 col-12 px-2">
              <div className="card-image-news"
               onClick={() => {
                if (data.name === "Internal Product") {
                  handleCardClick(data._id,CategoryData?.Category_A);
                } else {
                  handleCardClick2(data.link); 
                }
              }}>
                <img src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`} className="img-fluid news-card-img" alt="Home" />
                <div className="main_content_for_absoulte-news">
                  {data.logo_image.length > 0 || data.logo_title?
                  <div className="for_img_text">
                    {data.logo_image.length > 0 ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`}
                        className="img-fluid leftImg"
                        alt="News"
                      />
                    ) : (
                      ""
                    )}

                    <span
                      className="text-light for_img-text_for_mobile"
                      style={{ fontWeight: "400", fontSize: "16px" }}
                    >
                      {data.logo_title && data.logo_title.split(" ").slice(0, 2).join(" ").toUpperCase()}
                    </span>
                  </div>:""}

                  <div className="lorem-text-news">
                    <p
                      className="text-light top-image-text-news"
                      style={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px" }}
                    >
                      {data.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {(show ? fillerArray : ProductData).length === 0 && (
          <div className="text-center mt-4 not-found-div">
            <p>Data not found</p>
          </div>
        )}


<div className="row g-3 allNewsbox" style={{marginTop:"0px"}}>

          {(!show ? ProductData : fillerArray).map((data: any) => (
            data.status === "active" ? (
              <div className="col-lg-3 col-sm-6 col-md-4 All-col" key={data._id}>
                <div className="card custom-border-shadow-News" style={{ cursor: "pointer", height: "100%" }} onClick={() => {
                  if (data.name === "Internal Product") {
                    handleCardClick(data._id,CategoryData?.Category_E);
                  }
                  else {
                    handleCardClick2(data.link)
                  }
                }}>
                  <div className="custom-img-parent-News card-box">
                    <img src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`} className="card-img-top-news div-height" alt="..." />
                    <div className="image-lineae-gradient"></div>
                    <div className="img-absolute-News">
                      <div>
                        {data.logo_image.length > 0 ? (
                          <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`} alt="" className="src leftImg" />
                        ) : ""}
                      </div>
                      <div className="newschanel">
                        <small className="text-light custom-mobile-view-news">
                          {data.logo_title && data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="custom-card-body-news">
                    <p className="card-text for-custom-card-text-news card_content-news">
                    {data.title}
                    </p>
                  </div>
                </div>
              </div>) : null
          ))}
        </div>
      </div>
    </>
  );
}

export default SearchPage;
import React from "react";
import "../../components/footer/footer.css";
import { HiMiniArrowLongRight } from "react-icons/hi2";
import MicrosoftTeams from "../../assets/Images/Pharamaimg.jpg";
import { ImLocation } from "react-icons/im";
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="mainfooter backgroundblur mt-4">
        <div className="footer-top ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9 col-7">
                <div className="footer-left">
                  <span style={{ paddingTop: "10px" }}>
                    Let's Stay Connected
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-5">
                <div className="footer-right">
                  <form className="input-group footerrd">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                    <button
                      className="input-group-text btn footerrd"
                      style={{
                        backgroundColor: "#247496",
                        border: "2px solid white",
                      }}
                    >
                      <HiMiniArrowLongRight className="arrorstyl" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-logo">
            <div className="row">
              <div className="">
                <div className="footer-img-logo">
                <Link to="/">
                <img
                    src={MicrosoftTeams}
                    alt="Img"
                    className="f-main-image img-fluid"
                  />
            </Link>
                 
                </div>
              </div>
            </div>
          </div>

          <div className="footer-botm container">
            <div className="text-center">
              <span className="address">
                <ImLocation className="for-location-icon" /> 601, Atulya IT
                Park, Bhawarkua Main Road, Indore
              </span>
            </div>
            <div className="text-center ">
              <span className="phone px-3">
                <FaPhone className="for-location-icon" />
                &nbsp; +1234567890&nbsp;
              </span>
              
              <span className="phone px-3">
                <IoIosMail className="for-location-icon-email"/>
                &nbsp; example@gmail.com 
              </span>
            </div>
            <div className="botm-links">
              <div className="rowLink">
                <div className=" rowLink1">
                  <li>
                    <Link to="/aboutus" className="d-block">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer" className="d-block">
                      Disclaimer
                    </Link>
                  </li>
                </div>
                <div className=" rowLink2">
                  <li>
                    <Link to="/privacypolicy" className="d-block">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/termsconditions" className="d-block">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

import React, { useEffect, useState } from "react"; // Import useState
import { Link, useNavigate } from "react-router-dom";
import "../../components/signup/signup.css";
import vectorImage from "../../assets/Images/Vector (1).png";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";
import { useDispatch } from "react-redux";
import { login, userSignup2 } from "../../Reducers/authSlice";
import Swal from "sweetalert2";
import { Loader } from "../loader/Loader";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import * as Yup from "yup"; // Import Yup for validation
import { Formik, ErrorMessage, Field, Form } from "formik"; // Import Formik components
import eyeopen from "../../assets/Images/eyeopen.svg";
import eyeclosed from "../../assets/Images/eyeclosed.svg";

// 1. Define a type for the form values
interface SignupFormValues {
  fullname: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreeTerms: boolean;
}

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Step 1: Manage loading state


  const toggleShowPassword = () => {
    setShowPassword(true);
  };

  const toggleHidePassword = () => {
    setShowPassword(false);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(true);
  };

  const toggleHideConfirmPassword = () => {
    setShowConfirmPassword(false);
  };

  const validationSchema = Yup.object({
    fullname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Full name should only contain letters and spaces")
      .max(100, "Full name should not exceed 100 characters") // Full name validation
      .required("Full name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .max(50, "Email should not exceed 50 characters")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password should be at least 6 characters")
      .max(16, "Password should not exceed 16 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Please confirm your password"),
    agreeTerms: Yup.bool()
      .oneOf([true], "You must agree to the terms and conditions")
      .required("You must agree to the terms and conditions"),
  });

  // Default values for the form
  const defaultValue: SignupFormValues = {
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  };

  // Handle form submit with correct type for values
  const handleSubmit = (values: SignupFormValues) => {
    setLoading(true); // Show loader before making API request

    const { fullname, email, password, confirmPassword } = values;

    // Split fullname into firstname and lastname
    const [firstname, ...rest] = fullname.split(" ");
    const lastname = rest.join(" ") || firstname;

    // Prepare the body for the API request
    const body = {
      firstname,
      lastname,
      email,
      password,
      confirm_password: confirmPassword,
    };

    // Dispatch user signup action
    dispatch(userSignup2(body))
    .unwrap()
    .then((res: any) => {
      if (res.success) {
        // If registration is successful
        localStorage.setItem("userInfo", JSON.stringify(res.userDetails));
        dispatch(login());
        navigate("/");

        Swal.fire({
          icon: "success",
          title: "Registration successful!",
        });
      } else {
        // If registration failed but no error thrown (e.g., success flag is false)
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.message || "Something went wrong. Please try again.",
        });
      }
    })
    .catch((err: Error) => {
      // If an actual error is thrown (e.g., network error, server error)
      console.error("Signup failed:", err.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again.",
      });
    });
};

  useEffect(() => {
    dispatch(setMobileHeadingData("Sign-up"));
    dispatch(resetAllCardData());
  }, [dispatch]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = e.keyCode || e.which;
    const charStr = String.fromCharCode(charCode);

    if (!/^[A-Za-z\s]$/.test(charStr)) {
      e.preventDefault();
    }
  };

  return (
    <div className="container backgroundblur">
      <Formik
        initialValues={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="Form-field-parent float">
          <h2 className="sgnup-heading">Registration</h2>
          <hr />
          <div className="form-filed">
            <div className="form_filed-img">
              <img src={vectorImage} className="input1-icon user1-icon" alt="Person Icon" />
            </div>
            <Field
              className="regi-input-signup"
              type="text"
              id="fullname"
              name="fullname"
              placeholder="Full Name"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="fullname" />
          </div>

          <div className="form-filed">
            <div className="form_filed-img">
              <img src={emailIcon} className="input1-icon user1-icon" alt="Email Icon" />
            </div>
            <Field
              className="regi-input-signup"
              type="email"
              id="email"
              name="email"
              placeholder="Email Id"
            />
          </div>
          <div className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="email" />
          </div>

          <div className="form-filed">
            <div className="form_filed-img">
              <img src={passwordImage} className="input1-icon user1-icon" alt="Password Icon" />
            </div>
            <Field
              className="regi-input-signup"
              type={showPassword ? "text" : "password"}
            
              id="password"
              name="password"
              placeholder="Password"
            />
                <div className="eye-icon-container">
              {showPassword ? (
                <img
                  src={eyeopen}
                  className="eye-icon-size"
                  onClick={toggleHidePassword}
                />
              ) : (
                <img
                  src={eyeclosed}
                  className="eye-icon-size"
                  onClick={toggleShowPassword}
                />
              )}
            </div>
          </div>
          <div className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="password" />
          </div>

          <div className="form-filed">
            <div className="form_filed-img">
              <img src={passwordImage} className="input1-icon user1-icon" alt="Confirm Password Icon" />
            </div>
            <Field
              className="regi-input-signup"
              type={showConfirmPassword ? "text" : "password"}

              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Your Password"
            />
             <div className="eye-icon-container">
              {showConfirmPassword ? (
                <img
                  src={eyeopen}
                  className="eye-icon-size"
                  onClick={toggleHideConfirmPassword}
                />
              ) : (
                <img
                  src={eyeclosed}
                  className="eye-icon-size"
                  onClick={toggleShowConfirmPassword}
                />
              )}
            </div>
          </div>
          <div className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="confirmPassword" />
          </div>

          <div className="checkbox-container">
            <Field
              type="checkbox"
              id="agreeTerms"
              name="agreeTerms"
              className="checkbox-input"
            />
            <label htmlFor="agreeTerms" className="checkbox-label item">
              I agree to the{" "}
              <Link to="/termsconditions" className="item tcp">
                Terms &amp; Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy" className="item tcp">
                Privacy Policy
              </Link>
            </label>
          </div>
          <div className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="agreeTerms" />
          </div>

          <div className="sgn-btn">
            <button type="submit" className="btn btn-primary">SIGN UP</button>
            {loading && <Loader />} {/* Step 3: Show loader if loading is true */}
          </div>

          <div className="user1">
            <Link to="/login" className="Signup_link">
              EXISTING USER?
            </Link>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Signup;
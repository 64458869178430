import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import "../../components/signup/signup.css";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";
import { Loader } from "../loader/Loader";
import eyeopen from "../../assets/Images/eyeopen.svg";
import eyeclosed from "../../assets/Images/eyeclosed.svg";
import Swal from "sweetalert2";
import "./Forget.css";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [otpSent, setOtpSent] = useState(true);
  const [timer, setTimer] = useState(30);
  const [sentOtp, setSentOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(true);
  };

  const toggleHidePassword = () => {
    setShowPassword(false);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(true);
  };

  const toggleHideConfirmPassword = () => {
    setShowConfirmPassword(false);
  };

  useEffect(() => {
    let countdown: string | number | NodeJS.Timeout | undefined;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown); 
  }, [otpSent, timer]);

  const handleSendOtp = async () => {
    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid email.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/forgetUserPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();
      setLoading(false);

      if (response.ok && data.success) {
        setShowOtpForm(true);
        setOtpSent(true);
        setTimer(60);
        setSentOtp(data.otp);
        Swal.fire({
          icon: "success",
          title: "OTP Sent",
          text: "OTP has been sent to your email. Please check your inbox.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Email not found. Please enter a valid email address.",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while sending OTP.",
      });
    }
  };

  const handleValidateOtp = async () => {
    if (!otp) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter the OTP.",
      });
      return;
    }

    const otpPattern = /^[0-9]{6}$/;
    if (!otpPattern.test(otp)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "OTP must be a 6-digit number.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/verifyOTPForgetUserPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp: otp,
          }),
        }
      );

      const data = await response.json();
      setLoading(false);

      if (response.ok && data.success) {
        setIsOtpValid(true);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "OTP validated successfully!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message || "Invalid OTP. Please try again.",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while validating OTP.",
      });
    }
  };

  const handleUpdatePassword = async () => {
    if (!newPassword || !confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter both new password and confirm password.",
      });
      return;
    }

    if (newPassword.length < 6) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Password must be at least 6 characters long.",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Passwords do not match. Please ensure both passwords are identical.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/forgetUserPasswordUpdate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            new_password: newPassword,
            confirm_password: confirmPassword,
          }),
        }
      );

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Password updated successfully. You can now log in.",
        });
        setRedirectToHome(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update password. Please try again.",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the password.",
      });
    }
  };
  const handleResendOtp = async () => {
    if (timer > 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: `You can resend OTP after ${timer} seconds.`,
      });
      return;
    }
  
    await handleSendOtp();  
    setTimer(60);            
  };
  
  useEffect(() => {
    let countdown: string | number | NodeJS.Timeout | undefined;
  
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
    }
  
    return () => clearInterval(countdown);
  }, [otpSent, timer]);
  
  

  if (redirectToHome) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container backgroundblur">
      {!showOtpForm ? (
        <form className="Form-field-parent float">
          <h2 className="sgnup-heading">Enter Email</h2>
          <hr />
          <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={emailIcon}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="email"
              id="email"
              name="email"
              placeholder="Email Id"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="sgn-btn">
            <button
              type="button"
              onClick={handleSendOtp}
              disabled={!email}
            >
              SEND OTP
            </button>
          </div>
        </form>
      ) : (
        <form className="Form-field-parent float">
          {!isOtpValid ? (
            <>
              <h2 className="sgnup-heading">Enter OTP</h2>
              <hr />
              <div className="form-filed">
                <div className="form_filed-img">
                  <img
                    src={emailIcon}
                    className="input1-icon user1-icon"
                    alt="Person Icon"
                  />
                </div>
                <input
                  className="regi-input-signup"
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter Otp"
                  required
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <div className="otp-container">
                <div>
                  {timer > 0 ? (
                    <p>Time left to enter OTP: {timer} seconds</p>
                  ) : (
                    <p>OTP expired. Please request a new one.</p>
                  )}
                </div>
                <div>
                  <p
                    className="resend-otp"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </p>
                </div>
              </div>
              <div className="sgn-btn">
                <button type="button" onClick={handleValidateOtp} disabled={!otp}>
                  VALIDATE OTP
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className="sgnup-heading">Enter New Password</h2>
              <hr />
              <div className="form-filed">
                <div className="form_filed-img">
                  <img
                    src={passwordImage}
                    className="input1-icon user1-icon"
                    alt="Password Icon"
                  />
                </div>
                <input
                  className="regi-input-signup"
                  type={showPassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                   <div className="eye-icon-container">
              {showPassword ? (
                <img
                  src={eyeopen}
                  className="eye-icon-size"
                  onClick={toggleHidePassword}
                />
              ) : (
                <img
                  src={eyeclosed}
                  className="eye-icon-size"
                  onClick={toggleShowPassword}
                />
              )}
            </div>
              </div>
              <div className="form-filed">
                <div className="form_filed-img">
                  <img
                    src={passwordImage}
                    className="input1-icon user1-icon"
                    alt="Password Icon"
                  />
                </div>
                <input
                  className="regi-input-signup"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                    <div className="eye-icon-container">
              {showConfirmPassword ? (
                <img
                  src={eyeopen}
                  className="eye-icon-size"
                  onClick={toggleHideConfirmPassword}
                />
              ) : (
                <img
                  src={eyeclosed}
                  className="eye-icon-size"
                  onClick={toggleShowConfirmPassword}
                />
              )}
            </div>
              </div>
              <div className="sgn-btn">
                <button
                  type="button"
                  onClick={handleUpdatePassword}
                  disabled={!newPassword || !confirmPassword || newPassword !== confirmPassword}
                >
                  UPDATE
                </button>
              </div>
              <div className="user1">
                <Link to="/login" className="Signup_link">
                  EXISTING USER?
                </Link>
              </div>
            </>
          )}
        </form>
      )}
      {loading && <Loader />}
    </div>
  );
}

export default ForgetPassword;
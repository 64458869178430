import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/login/Login';
import Signup from './components/signup/Signup';
import News from './components/news/News';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import TermsConditions from './components/terms&conditions/TermsConditions';
import Disclaimer from './components/disclaimer/Disclaimer';
import SearchPage from './components/search/SearchPage'
import Header from './components/header/Header';
import Details from './components/details/Details';
import About from './components/aboutus/Aboutus';
import Regulatory from './components/regulatory/Regulatory';
import GmpTraining from './components/gmptraining/GmpTraining';
import Product from './components/product/Product';
import Business from './components/business/Business';
import PrivacyPolicy from './components/Privacy Policy/PrivacyPolicy';
import Video from './components/video/Video';
import Forget from './components/forget/Forget';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); 
  return (
    <>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/aboutus" element={<About/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/regulatory" element={<Regulatory/>} />
        <Route path="/gmptraining" element={<GmpTraining/>} />
        <Route path="/product" element={<Product/>} />
        <Route path='/business' element={<Business/>}/>
        <Route path='/video' element={<Video/>}/>
        <Route path='/forget' element={<Forget/>}/>
        <Route path='/search' element={<SearchPage/>}/>
        </Routes>
      <Footer />
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Doctor2 from "../../assets/Images/Group7262.png";
import "../../components/aboutus/AboutUs.css";
import { aboutUsData } from "../../Reducers/aboutSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { Link } from "react-router-dom";

function About() {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { AboutUsData, status, error } = useSelector((state: RootState) => state.about);
    const [showMore, setShowMore] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        dispatch(aboutUsData());
        dispatch(setMobileHeadingData("About"));
        dispatch(resetAllCardData());
    }, [dispatch]);

    const handleReadMore = (id: string) => {
        setShowMore((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    return (
        <div className="container backgroundblur">
            <div
                className="mainHeadAbout"
                style={{
                    textAlign: "center",
                    fontSize: "30px",
                    fontWeight: "700",
                    paddingTop: "10px",
                }}
            >
                <span>About Us</span>
            </div>

           

            {/* Content Section */}
            {AboutUsData.map((aboutItem) => {
                return (
                    <div className="container card mb-3 allCard" key={aboutItem._id}>
                        <div className="row aboutrow">
                            <div className="col-md-3 col-3" style={{ padding: "0px" }}>
                                <img
                                    className="img-fluid aboutImg"
                                    src={`${process.env.REACT_APP_API_URL}/${JSON.parse(aboutItem.about_us_image[0]).src}`}
                                    alt="Doctor"
                                />
                            </div>
                            <div className="col-md-9 col-9 text Web-about">
                                <span className="headText">
                                    <span style={{ color: "#247496", fontWeight: "bold" }}>
                                        {aboutItem.name}
                                    </span>
                                    <div
                                        className="textContent"
                                        style={{ textAlign: "justify" }}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: showMore[aboutItem._id]
                                                    ? aboutItem.about_us
                                                    : `${aboutItem.about_us.slice(0, 300)}...`,
                                            }}
                                        />
                                    </div>
                                    <button
                                        className="read-more-btn"
                                        onClick={() => handleReadMore(aboutItem._id)}
                                    >
                                        {showMore[aboutItem._id] ? "Read Less" : "Read More"}
                                    </button>
                                </span>
                            </div>

                            {/* Mobile view content */}
                            <div className="col-md-9 col-9 text mob-about">
                                <span className="headMobText">
                                    <span style={{ color: "#247496" }}>
                                        {aboutItem.name}
                                    </span>
                                    <div
                                        style={{ textAlign: "justify" }}
                                        className="textMobContent"
                                    >
                                        {aboutItem.about_us.slice(6, 100)}...
                                        <span>
                                            <Link
                                                to="#"
                                                style={{ color: "rgba(36, 116, 150, 1)" }}
                                                className="a-abot"
                                                onClick={() => handleReadMore(aboutItem._id)}
                                            >
                                                Read More...
                                            </Link>
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default About;